export const ratingsTableCols = [
  { prop: "id", label: "Receipt ID" },
  { prop: "retailerId", label: "Retailer ID" },
  { prop: "wholesalerId", label: "Wholesaler ID" },
  { prop: "areaId", label: "Area ID" },
];
export const ratingsMetrics = [
  {
    key: "receiptId",
    label: "Receipt ID",
    compName: "inputMetricNumbers",
  },
  {
    key: "creationDate",
    label: "Creation Date",
    compName: "dateRangeMetric",
  },
];
