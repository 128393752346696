<template>
  <el-dialog
    title="Bad Rates Details"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    :show-close="false"
    @close="closeDialog"
  >
    <div class="content">
      <div class="content__wrapper">
        <!-- <el-tag 
            size="medium"
        type="danger"
        v-for="item in badRatesList"
        :key="item.index"
        >{{item}}</el-tag> -->
        <el-alert
          v-for="item in badRatesList"
          :key="item.index"
          :closable="false"
          :title="item"
          show-icon
          type="warning"
          effect="dark"
        >
        </el-alert>
      </div>
    </div>

    <span slot="footer">
      <el-button @click="closeDialog">Cancel</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    badRatesList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async closeDialog() {
      let status = this.visible;
      !status;
      this.$emit("dialog-closed", status);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  &__wrapper {
    display: grid;
    direction: rtl;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 14px;
    span {
      font-size: 14px;
    }
  }
}
</style>
