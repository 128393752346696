<template>
  <div class="RatingsPage">
    <!-- Page Header -->
    <div class="page-header">
      <div class="page__header">
        <div class="page-header__wrapper">
          <div class="page-header__left">
            <h3 class="title"><i class="el-icon-star-on"></i>Ratings</h3>
          </div>
          <!-- <el-button type="primary" size="default">Upload Sheet</el-button> -->
        </div>
      </div>
    </div>
    <!-- /Page Header -->
    <!-- Filters -->
    <MetricFilterBox
      :defaultFilterData="ratingsMetrics"
      v-model="metricsList"
      @openMetric="saveMetrics"
      :clearBtnShowen="false"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            :is="metric.compName"
            v-model="filters[metric.key]"
            @change="filtersChanged(metric.key, $event)"
            :meta="metric.meta ? metric.meta : {}"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>
    <!-- Filters -->
    <!-- RatingsTable -->
    <styled-table :data="ratingsList" class="ratingsTable" style="width: 100%">
      <el-table-column
        v-for="col in ratingsTableCols"
        :key="col.prop"
        :prop="col.prop"
        :label="$t(col.label)"
      >
      </el-table-column>
      <el-table-column prop="createdAt" label="Creation Date" width="140">
        <template slot-scope="prop">
          <div>{{ prop.row.createdAt | moment("DD/MM/YYYY") }}</div>
          <div class="small-text">
            {{ prop.row.createdAt | moment("h:mm a") }}
            <!-- {{ prop.row.createdAt | moment("from") }} -->
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="ratingSubmittedAt"
        label="Submission Date"
        width="160"
      >
        <template slot-scope="prop">
          <div>{{ prop.row.ratingSubmittedAt | moment("DD/MM/YYYY") }}</div>
          <div class="small-text">
            {{ prop.row.ratingSubmittedAt | moment("h:mm a") }}
            <!-- {{ prop.row.createdAt | moment("from") }} -->
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Rate" width="120">
        <template slot-scope="prop">
          <el-tag v-if="prop.row.feedback_score === 1" type="success"
            >Good</el-tag
          >
          <el-tag v-else type="danger">Bad</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Bad Rates" width="180">
        <template slot-scope="prop">
          <el-button
            v-if="prop.row.badRates.length"
            size="small"
            type="warning"
            @click="openBadRatesDialog(prop.row.badRates)"
          >
            View
          </el-button>
          <p v-else>No Data</p>
        </template>
      </el-table-column>
      <el-table-column label="Validation" prop="ghoniem" fixed="right">
        <template slot-scope="prop">
          <!-- :value="checkValidation(prop.row.is_feedback_valid)" -->
          <!-- -->

          <el-switch
            v-model="prop.row.isFeedbackValid"
            @change="updateRateStatus(prop.row, $event)"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="Valid"
            inactive-text="Invalid"
          >
          </el-switch>
        </template>
      </el-table-column>
    </styled-table>
    <!-- RatingsTable -->

    <!-- Pagination -->
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="false"
        background
        layout="prev, pager, next"
        :page-size="50"
        :total="pagination.totalResultsCount"
        :current-page="currentPage"
        @current-change="handlePagination"
      ></el-pagination>
    </div>
    <!-- /Pagination -->

    <!-- Bad Rates Dialog -->
    <BadRatesDialog
      :badRatesList="badRatesData"
      :visible="badRatesDialogVisible"
      @dialog-closed="closeBadRatesDialog(false)"
    ></BadRatesDialog>
    <!-- /Bad Rates Dialog -->
  </div>
</template>

<script>
import _ from "lodash";
import styledTable from "../../../components/dataContainers/styledTable.vue";
import { ratingsService } from "../../../services/";
import { ratingsTableCols, ratingsMetrics } from "./ratings.data";
import metricbox from "@/components/transaction/metricbox";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import { marketingRatingsMetrics } from "@/components/transaction/metricboxComponents/index";
import BadRatesDialog from "./badRatesDialog.vue";
export default {
  components: {
    styledTable,
    metricbox,
    ...marketingRatingsMetrics,
    MetricFilterBox,
    BadRatesDialog,
  },
  name: "RatingsPage",
  computed: {
    currentPage() {
      return this.$route.query.page
        ? Number.parseInt(this.$route.query.page)
        : 1;
    },
  },
  async mounted() {
    const sessionFilters = JSON.parse(sessionStorage.getItem("filter"));
    this.filters = sessionFilters.ratingsMarketing
      ? sessionFilters.ratingsMarketing
      : {};

    const metricsSession = JSON.parse(localStorage.getItem("ratingsMetrics"));
    if (metricsSession) {
      this.metricsList = metricsSession;
    }
    await this.getRatings(this.currentPage, this.filters);
  },

  data() {
    return {
      badRatesDialogVisible: false,
      badRatesData: [],
      ratingsTableCols: ratingsTableCols,
      ratingsMetrics: ratingsMetrics,
      filters: {},
      metricsList: [],
      pagination: {
        totalResultsCount: 0,
      },
      ratingsList: [],
    };
  },
  methods: {
    openBadRatesDialog(data) {
      this.badRatesData = data;
      this.badRatesDialogVisible = true;
    },
    closeBadRatesDialog(status) {
      this.badRatesDialogVisible = status;
    },
    async getRatings(page = 1, currentFilters) {
      const ratingsResponse = await ratingsService.getRatings({
        page: page,
        filter: currentFilters,
      });
      this.ratingsList = ratingsResponse?.receipts;
      this.pagination = { totalResultsCount: ratingsResponse?.count };
    },
    async handlePagination(newPage) {
      await this.getRatings(newPage, this.filters);
      this.$router.push({
        query: {
          page: newPage,
        },
      });
    },
    async updateRateStatus(rowObject, value) {
      let updateObj = { id: rowObject.id };
      updateObj.isFeedbackValid = value;
      console.log(updateObj);
      try {
        let updateObj = { id: rowObject.id };
        updateObj.isFeedbackValid = value;
        const response = await ratingsService.updateRateValidation(updateObj);
        if (response && response.status === 200) {
          let responseSuccessMessage =
            rowObject.id +
            " " +
            "Receipt ID Rate Validation Updated To Be" +
            " " +
            " " +
            `${value ? "Valid" : "Invalid"}`;
          this.$globalFunctions.popupMessageWrapper(
            responseSuccessMessage,
            "success",
            4000
          );
        }
      } catch (error) {
        rowObject.isFeedbackValid = !value;
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
        setTimeout(() => {
          this.getPromocodes(this.currentPage);
        }, 2000);
      }
    },
    saveMetrics() {
      localStorage.setItem("ratingsMetrics", JSON.stringify(this.metricsList));
    },
    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      //add check if filter exist to avoid unnecessary api calls
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem("ratingsMetrics", JSON.stringify(this.metricsList));
      //fix lodash with reactivity issue
      this.metricsList = [...this.metricsList];
    },
    async filtersChanged(key, value) {
      if (_.isEmpty(value)) {
        this.filters[key] = undefined;
      }
      // save filters in session
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.ratingsMarketing = { ...this.filters };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.filters = { ...this.filters };

      if (this.$route.fullPath != "/marketing/ratings?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      await this.getRatings(this.currentPage, this.filters);
    },
  },
};
</script>

<style lang="scss">
.RatingsPage {
  .page__header {
    .page-header__wrapper {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }
    .title {
      display: flex;
      justify-content: center;
      align-content: center;
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fdcd12;
        font-size: 22px;
        margin-right: 4px;
      }
    }
  }
  .ratingsTable.el-table .cell {
    direction: ltr;
  }
  .ratingsTable .table-header:nth-child(1) {
    border-top-left-radius: 8px;
  }
  .ratingsTable .table-header:last-child {
    border-top-right-radius: 8px;
  }
  .ratingsTable .cell {
    direction: rtl;
  }
  .ratingsTable .table-header {
    background: #fccd13;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
